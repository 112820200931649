import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { colors, fonts } from '../../styles/variables';
import { normalize } from '../../styles/normalize';

export const LayoutStyles = css`
  @import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

  ${normalize}

  body {
    font-family: ${fonts.default}, sans-serif;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: ${colors.darkNavyBlue};
  }
`;

export const Wrapper = styled.div`
  // padding-top: 16px;
`;

export const StickyFixWrapper = styled.div``;
