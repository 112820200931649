import styled from '@emotion/styled';
import { breakpoints, colors } from '../../styles/variables';
import { Link } from 'gatsby';

export const Legals = styled.section`
  display: flex;
  margin-top: 132px;
  padding: 10px 32px;
  background: #eae6de;
  justify-content: center;

  @media screen and (max-width: ${breakpoints.lg}px) {
    margin-top: 40px;
  }
`;

export const LegalsContent = styled.div`
  max-width: 700px;
  width: 100%;
  color: ${colors.lightGray};
  font-size: 12px;
  line-height: 1.75;
  text-align: center;

  p {
    margin: 0;
  }
`;

export const Wrapper = styled.footer`
  display: flex;
  height: 87px;
  padding: 0 32px;
  background-color: ${colors.navyBlue};
  align-items: center;
  justify-content: space-between;
  color: ${colors.white};

  @media screen and (max-width: ${breakpoints.lg}px) {
    justify-content: center;
    flex-direction: column;
  }
  @media screen and (max-width: ${breakpoints.md}px) {
    height: auto;
    padding-top: 20px;
    padding-bottom: 20px;
  }
`;

export const Copyright = styled.p`
  margin: 0;

  @media screen and (max-width: ${breakpoints.lg}px) {
    margin-bottom: 10px;
  }
`;

export const NavList = styled.ul`
  display: flex;
  margin: 0;
  padding: 0;

  @media screen and (max-width: ${breakpoints.md}px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-row-gap: 10px;
    text-align: center;
  }
`;

export const NavListItem = styled.li`
  margin: 0 16px;
  list-style: none;

  &:first-of-type {
    margin-left: 0;
  }
  &:last-of-type {
    margin-right: 0;
  }
`;

export const NavListLink = styled.a`
  color: ${colors.white};
  text-decoration: none;
  transition: color 0.1s ease-in-out;

  &:hover,
  &:focus {
    color: #ffd858;
  }
  &:active {
    color: ${colors.white};
  }
`;

export const NavListRouterLink = styled(Link)`
  color: ${colors.white};
  text-decoration: none;
  transition: color 0.1s ease-in-out;

  &:hover,
  &:focus {
    color: #ffd858;
  }
  &:active {
    color: ${colors.white};
  }
`;
