/* eslint-disable max-len */

export const colors = {
  biscuit: '#eae6de',
  black: '#000',
  blue: '#004dff',
  darkNavyBlue: '#192a4a',
  lightGray: '#67717b',
  navyBlue: '#0a2460',
  white: '#fff',
};

export const fonts = {
  default: 'Inter',
};

export const breakpoints = {
  xs: 280,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1400,
};

export const widths = {
  md: 720,
  lg: 960,
  xl: 1140,
};

export const dimensions = {
  fontSize: {
    small: 12,
    regular: 16,
    medium: 20,
    large: 24,
    headingMajor: 48,
    heading: 42,
  },
  headingSizes: {
    h1: 2.441,
    h2: 1.953,
    h3: 1.563,
    h4: 1.25,
  },
  lineHeight: {
    regular: 1.45,
    medium: 1.5,
    large: 1.5,
    headingMajor: 1.25,
    heading: 1.28,
  },
  containerPadding: 30,
};

export const heights = {
  header: 60,
};
