import styled from '@emotion/styled';
import { Link } from 'gatsby';
import { transparentize } from 'polished';
import { css } from '@emotion/react';
import { breakpoints, colors, dimensions } from '../../styles/variables';
import DoodleIllustration from '../../assets/doodles/topbar.inline.svg';

interface WrapperProps {
  isSticky: boolean;
  isColored: boolean;
  isTransparent: boolean;
  removeMB: boolean;
}

export const Wrapper = styled.header<WrapperProps>`
  display: flex;
  ${({removeMB}) => 
    !removeMB &&
    `margin-bottom: 18px;
    ` 
  }
 
  padding: 32px 32px 14px 32px;
  
  top: 0;
  z-index: 100;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  
  ${({ isTransparent }) =>
    isTransparent &&
    ` 
  background-color: transparent;
  `};


  ${({ isSticky }) =>
    isSticky &&
    ` 
    position: sticky;
      border-bottom: 1px solid #d3dae0;
  `};
  ${({ isColored }) =>
    isColored &&
    ` 
    background-color: ${colors.biscuit};
    padding-bottom: 40px;
    margin-bottom: 0px !important;
  `};


  @media screen and (max-width: ${breakpoints.lg}px) {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  @media screen and (max-width: ${breakpoints.md}px) {
    padding: 10px;
    ${({removeMB}) => 
    removeMB &&
    `margin-bottom: -18px;
    ` 
  }
 
  }
`;

export const Container = styled.div`
  display: flex;
  width: 100%;
  max-width: 1140px;
  margin: 0 auto;
  align-items: center;
  justify-content: space-between;
`;

export const Logo = styled(Link)`
  font-size: 0;

  @media screen and (max-width: ${breakpoints.md}px) {
    transform: scale(0.8);
    transform-origin: left center;
  }

  svg {
    width: 160px;
    height: 40px;
  }

  @media screen and (max-width: ${breakpoints.lg}px) {
    margin-bottom: 0;
  }
`;

export const LogoAIIllustration = styled.div`
  width: 400px;
  overflow: hidden;
  background: url('/logo.inline-AI.svg') no-repeat;
  background-size: contain;
  height: 100px;
  margin-left: -40px;
  @media screen and (max-width: ${breakpoints.sm}px) {
    width: 270px;
    margin-top: 5px;
    margin-left: -35px;
  }
`;

export const Doodle = styled(DoodleIllustration)`
  width: 139px;
  height: 149px;
  position: absolute;
  top: -95px;
  right: 26%;
  z-index: -1;
`;

export const Nav = styled.nav`
  margin-top: -5px;

  @media screen and (max-width: ${breakpoints.lg}px) {
    margin-top: 0;
  }
`;

export const NavList = styled.ul`
  display: flex;
  margin: 0;
  padding: 0;
  align-items: center;
  font-size: ${dimensions.fontSize.regular};
  color: ${colors.darkNavyBlue};
`;

export const NavItem = styled.li`
  margin-right: 32px;
  list-style: none;

  &:last-of-type {
    margin-right: 0;
  }

  @media screen and (max-width: ${breakpoints.lg}px) {
    margin-right: 20px;
  }
  @media screen and (max-width: ${breakpoints.md}px) {
    margin-right: 0;
    margin-bottom: 0;
  }
`;

const NavLinkStyles = css`
  color: currentColor;
  text-decoration: none;

  &:hover,
  &:focus {
    text-decoration: underline;
  }
  &:active {
    text-decoration: none;
  }
`;

export const NavLink = styled.a`
  ${NavLinkStyles}
`;

export const NavRouterLink = styled(Link)`
  ${NavLinkStyles}
`;

export const NavButton = styled.a`
  display: inline-flex;
  height: 40px;
  padding: 0 16px;
  border-radius: 20px;
  background-color: ${colors.blue};
  align-items: center;
  box-shadow: 0 1px 2px ${transparentize(0.9, colors.black)};
  color: ${colors.white};
  font-weight: 600;
  text-transform: uppercase;
  cursor: pointer;
  transition: background-color 0.1s ease-in-out;

  &,
  &:hover,
  &:focus,
  &:active {
    text-decoration: none;
  }

  &:hover,
  &:focus,
  &:active {
    background-color: #1f62ff;
  }
`;
